<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :editar="false" :eliminar="false" :acciones="false">
      <template v-slot:before>
        <button v-if="winOptions.selectable && mode == 'view'" class="button button-fill button-color-primary"
          @click="winOptions.onSelect(self)">
          <fa-icon icon="mouse-pointer" />
          <span>Seleccionar</span>
        </button>
        <button class="button" name="newmultiple" v-tooltip="'Importar múltiples imágenes'"
          @click="$refs.inputmultiple.click()">
          <fa-icon icon="upload" />
          <span>Importar</span>
          <input ref="inputmultiple" type="file" name="files[]" multiple
            style="position: absolute; visibility: hidden; display: none" @change="onChangeInputMultiple" />
        </button>
        <button v-if="mode == 'view' || mode == 'empty' || mode == 'search'" class="button button-fill" @click="
      setMode('new');
    $refs['main-image'].$children[0].selectImage();
    " v-tooltip="$t('rform.new')">
          <fa-icon :icon="['far', 'file']" />
          <span>{{ $t("rform.new") }}</span>
        </button>
        <button class="button" name="delete-multiple" v-tooltip="'Eliminar seleccionados'"
          v-if="mode != 'edit' && mode != 'new' && selectedItems.length" @click="eliminarMultiple">
          <fa-icon icon="trash" />
          <span>Eliminar</span>
        </button>
      </template>
      <template v-slot:after>
        <div class="field inline-label item-input" style="position: relative; margin-left: 10px;">
          <div class="item-inner"><label class="item-title item-label">Orden</label>
            <div class="item-input-wrap">
              <select placeholder="Orden" style="width: 110px; "
                @change="order = $event.target.value; orderDir = $event.target.options[$event.target.selectedIndex].getAttribute('dir'); initRemoteData('filter')">
                <option value="codigo" dir="ASC">Código asc</option>
                <option value="codigo" dir="DESC">Código desc</option>
                <option value="create_date" dir="ASC">Fecha asc</option>
                <option value="create_date" dir="DESC">Fecha desc</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field inline-label item-input" style="position: relative; margin-left: 10px;">
          <div class="item-inner"><label class="item-title item-label">Columnas</label>
            <div class="item-input-wrap">
              <select placeholder="Orden" style="width: 60px; " @change="cols = $event.target.value;">
                <option value="3" :selected="cols == 3">3</option>
                <option value="4" :selected="cols == 4">4</option>
                <option value="5" :selected="cols == 5">5</option>
                <option value="6" :selected="cols == 6">6</option>
                <option value="7" :selected="cols == 7">7</option>
                <option value="8" :selected="cols == 8">8</option>
              </select>
            </div>
          </div>
        </div>
        <r-progressbar style="display: block" v-if="valueProgressbar" :value="valueProgressbar"
          :text="textProgressbar"></r-progressbar>
      </template>
    </nav-form>
    <section class="form" style="display: inline-block">
      <article style="width: 250px; height: 566px">
        <field name="solo_principales" widget="checkbox" inline-label reverse label="Buscar sólo imágenes principales"
          style="top: 3px; left: 6px" searchable no-bind-field />
        <font-awesome-layers class="remove-filter"
          style="position: absolute; right: 20px; top: 30px; z-index: 9; cursor: pointer;" v-show="mode == 'search'"
          @click="$set(self, 'formData', {});
    setFilter({});
    ">
          <fa-icon icon="filter" />
          <fa-icon icon="times" style="right: 0px" transform="shrink-6" />
        </font-awesome-layers>
        <r-tabs style="top: 20px; left: 0px; height: 297px; width: 245px" :buttons="['Artículos', 'Modelos']">
          <div class="tab filtro_articulos" model="articulo">
            <field name="articulos.nombre" :readonly="true" widget="char" placeholder="Descripción" searchable
              width="220px" style="top: 10px; left: 7px; width: 60px" no-bind-field />
            <field name="articulos.proveedor_id" placeholder="Proveedor" widget="m2o" searchable dbAdapter="proveedor"
              labelProp="nombre_comercial" width="220px" style="top: 35px; left: 7px" no-bind-field />
            <field name="articulos.seccion_id" placeholder="Sección" widget="m2o" searchable dbAdapter="seccion"
              width="220px" style="top: 60px; left: 7px" no-bind-field />
            <field name="articulos.familia_id" placeholder="Familia" widget="m2o" searchable dbAdapter="familia"
              width="220px" style="top: 85px; left: 7px" no-bind-field />
            <field name="articulos.codigo" placeholder="Artículo" searchable width="220px" style="top: 110px; left: 7px"
              no-bind-field />
            <field name="articulos.atributos" widget="handsontable" :height="117" :minRows="4" searchable
              style="top: 135px; left: 4px; width: 225px" customprop="atributos_customprop" no-bind-field :columns="[
      {
        name: 'atributo_id',
        model: 'atributo',
        header: 'Atributo',
        type: 'm2o',
        label: 'nombre',
      },
      {
        name: 'id',
        model: 'valor_atributo',
        header: 'Valor',
        type: 'm2o',
        label: 'nombre',
        filter: (value, row, prop) =>
          value && value['atributo_id']
            ? ['atributo_id', '=', value['atributo_id']]
            : [],
      },
    ]" />
          </div>
          <div class="tab filtro_modelos" model="modelo">
            <field name="modelos.nombre" :readonly="true" widget="char" placeholder="Descripción" searchable
              width="220px" style="top: 10px; left: 7px; width: 60px" no-bind-field />
            <field name="modelos.proveedor_id" placeholder="Proveedor" widget="m2o" searchable dbAdapter="proveedor"
              labelProp="nombre_comercial" width="220px" style="top: 35px; left: 7px" no-bind-field />
            <field name="modelos.seccion_id" placeholder="Sección" widget="m2o" searchable dbAdapter="seccion"
              width="220px" style="top: 60px; left: 7px" no-bind-field />
            <field name="modelos.familia_id" placeholder="Familia" widget="m2o" searchable dbAdapter="familia"
              width="220px" style="top: 85px; left: 7px" no-bind-field />
            <field name="modelos.codigo" placeholder="Artículo" searchable width="220px" style="top: 110px; left: 7px"
              no-bind-field />
            <field name="modelos.atributos" widget="handsontable" :height="117" :minRows="4" searchable
              style="top: 135px; left: 4px; width: 225px" customprop="atributos_customprop" no-bind-field :columns="[
      {
        name: 'atributo_id',
        model: 'atributo',
        header: 'Atributo',
        type: 'm2o',
        label: 'nombre',
      },
      {
        name: 'id',
        model: 'valor_atributo',
        header: 'Valor',
        type: 'm2o',
        label: 'nombre',
        filter: (value, row, prop) =>
          value && value['atributo_id']
            ? ['atributo_id', '=', value['atributo_id']]
            : [],
      },
    ]" />
          </div>
        </r-tabs>
        <field ref="main-image" widget="image" name="imagen"
          style="width: 238px; height: 238px; bottom: 10px; left: 5px" />
        <field name="codigo" widget="input" label="Código" placeholder="Cod" width="50px" required
          inputStyle="font-weight:bold; text-align:center;" style="bottom: 210px; left: 6px" searchable
          :readonly="mode == 'edit'" />
      </article>
    </section>
    <section style="
              float: right;
              width: 700px;
              height: 526px;
              margin: 10px;
              overflow: auto;
            ">
      <span style="
                position: absolute;
                bottom: 17px;
                right: 23px;
                background: #ddd;
                min-width: 40px;
                text-align: center;
              ">{{ count }}</span>
      <drag-select-container selectorClass="rlist-item" :selectedItems="selectedItems" @change="selectedItems = $event"
        style="
                overflow-y: scroll;
                height: 100%;
                width: 100%;
                border: 1px solid #ccc;
              " class="rlist-list" ref="rlist">
        <template v-if="items.length">
          <div @click="
      $event.shiftKey || $event.ctrlKey ? null : loadItem(item[primary])
      " v-for="item in items" :key="item[primary]" :vkey="item[primary]" class="rlist-item" :style="'width: calc(100% / ' + cols + ' - 6px)'" :class="{
      'rlist-item-selected': selectedItems.some(
        (vkey) => vkey == item[primary]
      ),
    }">
            <div style="text-align: right">{{ item.codigo }}</div>
            <img :src="item.imagen_thumbnail
      ? item.imagen_thumbnail
      : require('./../assets/noimage.png')
      " style="width: 100%; height: 100%" />
          </div>
          <div v-show="loadingTable" style="width: 100%; text-align: center">
            <fa-icon icon="spinner" spin />
          </div>
        </template>
        <div v-else>Sin resultados</div>
      </drag-select-container>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import DragSelect from "./../components/DragSelect.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  components: {
    "drag-select-container": DragSelect,
  },
  /*watch: {
    mode: function(newVal, oldVal) {
      var self = this;
      if (newVal == "new") {
        window.console.log(self.$refs["main-image"].$children[0].selectImage());
      }
    }
  },*/
  data: function () {
    return {
      title: "Galería de artículos",
      dbAdapter: "imagen_articulo",
      primary: "codigo",
      sequence: { name: "imagen_articulo" },
      valueProgressbar: 0,
      textProgressbar: "Importando...",
      selectedItems: [],
      rowsPerPage: 180,
      cols: 6
    };
  },
  methods: {
    eliminarMultiple() {
      let self = this;
      self.app.confirm(
        "¿Seguro que deseas eliminar las imágenes seleccionadas?",
        function () {
          var err = 0;
          var n = 0;
          var p = Promise.resolve();
          self.selectedItems.forEach(function (itemId) {
            p = p.then(
              (_) =>
                new Promise(function (resolve, reject) {
                  window.DB.delete(self.dbAdapter, {
                    itemId,
                  })
                    .then((res) => {
                      n++;
                      self.removeData(itemId);
                    })
                    .catch(function (res) {
                      err++;
                    })
                    .finally(function () {
                      resolve();
                    });
                })
            );
          });
          p.then(function () {
            if (err)
              self.app.toast(
                err + " imágenes no puderon ser eliminadas",
                "error"
              );
            if (n)
              self.app.toast(
                n + " imágenes se eliminaron con éxito",
                "success"
              );
          });
        }
      );
    },
    getFilter: function () {
      var self = this;
      var res = [];
      if (self.formData.codigo)
        res.push(["codigo", "*LIKE*", self.formData.codigo]);
      if (self.formData.articulos) {
        let a = self.formData.articulos;
        let resa = ["AND"];
        if (a.nombre) resa.push(["nombre", "*LIKE*", a.nombre]);
        if (a.proveedor_id)
          resa.push(["proveedor_id", "=", a.proveedor_id.codigo]);
        if (a.seccion_id) resa.push(["seccion_id", "=", a.seccion_id.codigo]);
        if (a.familia_id) resa.push(["familia_id", "=", a.familia_id.codigo]);
        if (a.codigo) resa.push(["codigo", "*LIKE*", a.codigo]);
        if (a.atributos) {
          a.atributos.forEach(function (at) {
            if (at.id)
              resa.push(["atributos.id", "=", at.id]);
          });
        }
        window.console.log(resa);
        if (resa.length > 1) {
          res.push(["articulos_thumbnail", "IN", resa]);
          if (!self.formData.solo_principales) {
            res.unshift("OR");
            res.push(["articulos_thumbnail1", "IN", resa]);
            res.push(["articulos_thumbnail2", "IN", resa]);
            res.push(["articulos_thumbnail3", "IN", resa]);
            res.push(["articulos_thumbnail4", "IN", resa]);
          }
        }
      }
      if (self.formData.modelos) {
        let a = self.formData.modelos;
        let resm = ["AND"];
        if (a.nombre) resm.push(["nombre", "*LIKE*", a.nombre]);
        if (a.proveedor_id)
          resm.push(["proveedor_id", "=", a.proveedor_id.codigo]);
        if (a.seccion_id) resm.push(["seccion_id", "=", a.seccion_id.codigo]);
        if (a.familia_id) resm.push(["familia_id", "=", a.familia_id.codigo]);
        if (a.codigo) resm.push(["codigo", "*LIKE*", a.codigo]);
        if (a.atributos) {
          a.atributos.forEach(function (at) {
            if (at.id)
              resm.push(["atributos.id", "=", at.id]);
          });
        }
        if (resm.length > 1) {
          res.push(["modelos_thumbnail", "IN", resm]);
          if (!self.formData.solo_principales) {
            res.unshift("OR");
            res.push(["modelos_thumbnail1", "IN", resm]);
            res.push(["modelos_thumbnail2", "IN", resm]);
            res.push(["modelos_thumbnail3", "IN", resm]);
            res.push(["modelos_thumbnail4", "IN", resm]);
          }
        }
      }
      return res;
    },
    onChangeInputMultiple(e) {
      var self = this;
      let input = e.target;
      if (!input.files || typeof input.files[0] == "undefined") return;
      var usarNombre = false;
      var usarNombreNumerico = false;
      var sobrescribir = 0;
      var errores = [];

      self.app.confirm(
        "¿Seguro que deseas importar " + input.files.length + " imágenes?",
        function () {
          self.mode = "new";
          self.$nextTick(function () {
            self.$refs["main-image"].field.$refs.cropper.$on(
              "change",
              function (val) {
                let data = {
                  imagen: self.formData.imagen,
                  origin: "import",
                  sobrescribir: sobrescribir,
                };
                if (usarNombre)
                  data["codigo"] = input.files[n].name.substring(0, 16);
                else if (usarNombreNumerico) {
                  data["codigo"] = parseInt(
                    input.files[n].name.replace(/[^0-9]/g, "").substring(0, 16)
                  );
                }
                window.DB.save(self.dbAdapter, {
                  data,
                })
                  .catch(function () {
                    errores.push(input.files[n].name);
                  })
                  .finally(function () {
                    next();
                  });
              }
            );
            var next = function () {
              n++;
              self.valueProgressbar = Math.floor(
                (n * 100) / input.files.length
              );
              if (typeof input.files[n] == "undefined") {
                self.valueProgressbar = 0;
                self.mode = "empty";
                self.formData = {};
                if (n - errores.length) {
                  self.app.toast(
                    n - errores.length + " imágenes importadas con éxito.",
                    "success"
                  );
                }
                if (errores.length) {
                  self.app.toast(
                    errores.length +
                    " imágenes no se han podido importar porque ya existe una imagen con el mismo código o porque no se han podido cargar",
                    "error",
                    { duration: 10000 }
                  );
                }
                input.value = null;
                self.initRemoteData();
              } else {
                reader.readAsDataURL(input.files[n]);
              }
            };
            var n = 0;
            var reader = new FileReader();
            reader.onload = function (e) {
              try {
                self.$refs["main-image"].field.$refs.cropper.setImg(
                  e.target.result
                );
              } catch (e) {
                errores.push(input.files[n].name);
                self.app.toast(input.files[n].name + ": " + e, "error");
                next();
              }
            };
            reader.readAsDataURL(input.files[n]);
          });
        },
        function () {
          input.value = null;
        },
        [
          [
            '<input type="checkbox" name="asignarCodigo" data-label="Asignar código utilizando el nombre de la imagen">',
            "change",
            function (instance, toast, input, e) {
              usarNombre = input.checked;
              if (!usarNombre) {
                window
                  .$('input[name="sobrescribirImagenes"]')
                  .prop("checked", false)
                  .prop("disabled", true);
                sobrescribir = 0;
              } else {
                window
                  .$('input[name="asignarCodigoNumerico"]')
                  .prop("checked", false);
                window
                  .$('[name="sobrescribirImagenes"]')
                  .prop("disabled", false);
                usarNombreNumerico = false;
              }
            },
          ],
          [
            '<input type="checkbox" name="asignarCodigoNumerico" data-label="Asignar código utilizando la parte numérica del nombre de la imagen">',
            "change",
            function (instance, toast, input, e) {
              usarNombreNumerico = input.checked;
              if (!usarNombreNumerico) {
                window
                  .$('input[name="sobrescribirImagenes"]')
                  .prop("checked", false)
                  .prop("disabled", true);
                sobrescribir = 0;
              } else {
                window.$('input[name="asignarCodigo"]').prop("checked", false);
                window
                  .$('[name="sobrescribirImagenes"]')
                  .prop("disabled", false);
                usarNombre = false;
              }
            },
          ],
          [
            '<input type="checkbox" name="sobrescribirImagenes" disabled="true" data-label="Sobreescribir las imágenes con el mismo código">',
            "click",
            function (instance, toast, input, e) {
              sobrescribir = input.checked ? 1 : 0;
            },
          ],
        ]
      );
    },
  },
};
</script>
<style>
.iziToast-inputs-child[data-label] {
  display: block;
}

.iziToast-inputs-child[data-label]::after {
  content: attr(data-label);
  white-space: nowrap;
  position: absolute;
  left: 130%;
  font-size: 12px;
  color: #888;
}

.iziToast-inputs {
  min-width: 375px;
}
</style>